.testimonial-image {
    width: 100px;
    max-height: 100px;
    display: block;
    aspect-ratio: 1/1;
    height: 100px;
    margin: auto;
    margin-top: -50px;
    outline: 2px solid #462d2d;
outline-offset: 2px;
  }

  .testimonial-wrapper {
    min-width: clamp(300px, 30vw, 450px);
    display: inline-block;
    padding: 10px 20px;
    box-shadow: 0px 0px 15px #ddd;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    margin: auto;
  }
  .testimonial-wrapper > * {
    margin-bottom: 5px;
  }
  .star-icon {
   display: inline-block;
  }
  .star-icon:not(:last-child){
    margin-right: 5px;
  } 
  .testimonials-inners {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
  overflow: scroll;
  grid-auto-flow: column;
  padding: 20px 5px;
    padding-top: 50px;
  }
  .reviewr-name {
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
  }


  @media screen and (min-width: 925px) {
    
  }