.usp-image {
    display: block;
    max-width: 50px;
    outline: 1px solid #000;
    border-radius: 50%;
    padding: 5px;
}
.usp-item-container{
    grid-template-columns: repeat(4, minmax(250px,auto));
grid-auto-flow: column;
overflow-x: scroll;
padding: 20px 10px;
}
.usp-description{
    margin-bottom: 0px;
    font-size: 1.1em;
}
.usp-item-container .usp-item {
    padding: 3em !important;
    border-radius: 5px;
    border-color: #462d2d !important;
    background: #f4f1e0;
    color: #462d2d;
    box-shadow: 0px 0px 15px #ddda;
transition: 0.4s all;
cursor: pointer;
position: relative;
}
.usp-frame{
    position: absolute;
    user-select: none;
    pointer-events: none;
    height: auto;
    max-width: 110px;
    padding: 5px;
}
.usp-frame.top{
    top: 0px;
    right: 0px;
}
.usp-frame.bottom{
    left: 0px;
    bottom: 0px;
    transform: rotateX(180deg)rotateZ(270deg);
}
.usp-item:hover {
    transform: translateY(-15px);
    box-shadow: 0px 20px 15px #aaaa;
}
