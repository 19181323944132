@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

:root{
  --bg-primary : #fff;
  --logo-theme: #462d2d;
}
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: sans-serif;
}

a, a:hover {
  text-decoration: none;
  color: unset;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(124, 114, 114);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(88, 65, 65); 
}

.bg-primary {
  background-color: #f4f1e0 !important;
}


.bg-tertiary {
  background-color: var(--logo-theme) !important;
}

.text-brown {
  color: #321e1e !important;
}

.cursor-pointer {
  cursor: pointer;
}

.h-full {
  height: 100vh;
}

.w-full {
  width: 100vw;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.text-smaller {
  font-size: 0.8rem;
}

.carousel-item {
  height: 400px;
  position: relative;
}

.overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}


.carousel-item img:nth-child(1) {
  height: 400px;
  object-fit: contain!important;
  z-index: 2!important;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-item img:nth-child(2) {
  height: 400px;
  width: 100%;
  object-fit: cover!important;
  z-index: 0!important;
  position: absolute;
  top: 0;
  left: 0;
}
[role="search"] {
  width: min(100%,450px);
  position: relative;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  z-index: 6!important;
}

.carousel-indicators button {
  border-radius: 50%;
  height: 14px!important;
  width: 14px!important;
  margin-left: 5px!important;
  margin-right: 5px!important;
}

.navbarSeachInput {
  border-radius: 45px;
}

.navbarSeachBtn {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0px 45px 45px 0px;
  background-color: #f4f1e0;
  border: 0px;
  width: min(15%,55px);
}

.navbarSeachBtn:hover {
  background-color: #dad7c8;
}

.navbarCategories div:nth-child(2):hover div.mensSubCat,
.navbarCategories div:nth-child(3):hover div.mensSubCat,
.navbarCategories div:nth-child(4):hover div.mensSubCat {
  display: block!important;
  background-color: #f4f1e0;
  color: #462d2d;
  border-radius:5px;
}

.navbarCategories div:nth-child(2):hover div.mensSubCat .dropdown-item,
.navbarCategories div:nth-child(3):hover div.mensSubCat .dropdown-item,
.navbarCategories div:nth-child(4):hover div.mensSubCat .dropdown-item {
  color: #462d2d!important;
}

.navbarCategories div:nth-child(2):hover div.mensSubCat .dropdown-item:hover,
.navbarCategories div:nth-child(3):hover div.mensSubCat .dropdown-item:hover,
.navbarCategories div:nth-child(4):hover div.mensSubCat .dropdown-item:hover {
  background-color: #d6d1b3;
}
.uppercase{
  text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.product-card {
  width: 250px;
  height: 450px;
  margin: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
}

.newLaunch-card {
  width: 400px;
  height: 400px;
  margin: 0px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #f1f1eb;
}

.product-cardName {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.discount-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #554242;
  color: #f4f1e0;
  border-radius: 5px;
  font-size: 1em;
  z-index: 10;
}

.color-div {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}
.card-body * {
  font-size: 0.9em !important;
}
.card-body > *:not(.wishlist-div) {
  margin-bottom: 5px !important;
}
.card-title.product-cardName {
  font-size: 1em !important;
}
.wishlist-div {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #776d6d;
  color: #f4f1e0;
  border-radius: 5px;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  z-index: -200;
}

.product-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
}

.subcatRow {
  z-index: 25;
}
.grid {
  display: grid !important;
}
.grid-2{
  grid-template-columns: repeat(2, 1fr);
}
.grid-3{
  grid-template-columns: repeat(3, 1fr);
}
.grid-4{
  grid-template-columns: repeat(4, 1fr);
}
.gap-5{
  gap: 5px;
}
.gap-10{
  gap: 10px;
}
.gap-15{
  gap: 15px;
}
.gap-20{
  gap: 20px;
}
.gap-25{
  gap: 25px;
}

.product-card:hover .product-img, .newLaunch-card:hover .product-img {
  transform: scale(1.3);
  transform-origin: 50% 50%;
}

.product-card:hover .wishlist-div {
  top: -42px;
  z-index: 20;
}

.img-container {
  overflow: hidden;
  height: inherit;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scroll-btn {
  position: absolute;
  /* background-color: #f4f1e0; */
  background-color: rgba(0, 0, 0, 0.5);
  height: 50px;
  width: 50px;
  border: 0px;
  border-radius: 50%;
  z-index: 10;
  color: #fff;
}

.scroll-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.scroll-btn.prev {
  left: -10px;
}

.scroll-btn.next {
  right: -10px;
}

.flex-3 {
  flex: 3 1;
}

.flex-2 {
  flex: 2 1;
}

.flex-1 {
  flex: 1 1;
}

.flex-4 {
  flex: 4 1;
}

.flex-5 {
  flex: 5 1;
}

.flex-0 {
  flex: 0 1;
}

.top-16 {
  top: 4rem;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.w-20 {
  width: 5rem;
}

.h-24 {
  height: 6rem;
}

.font-bold {
  font-weight: 700;
}

.sizeBtn {
  border: 1px solid #462d2d;
  border-radius: 5px;
  background-color: #f4f1e0;
  color: #462d2d;
  font-size: 1.2rem;
  padding: 5px 15px;
  margin: 5px;
  cursor: pointer;
}

.sizeBtn:hover {
  background-color: #d6d1b3;
}

.sizeBtn-selected, .sizeBtn-selected:hover {
  background-color: #462d2d;
  color: #f4f1e0;
}

.colorImageBtn {
  border: 1px solid #462d2d;
  color: #462d2d;
  margin: 5px;
  cursor: pointer;
  height: 80px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorImageBtn:hover {
  border: 2px solid #462d2d;
}

.colorImageBtn-selected, .colorImageBtn-selected:hover {
  border: 3px solid #462d2d;
}

.colorImageBtn img {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.colorImageBtn p {
  font-size: 0.8rem;
  bottom: -44px;
}

.btn-tertiary {
  background-color: #462d2d;
  color: #fff;
  border: 1px solid #462d2d;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 1.1rem;
  cursor: pointer;
}

.btn-tertiary:hover {
  background-color: #554242;
  color: #fff;
}

.w-10 {
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.btn-creme {
  background-color: #f4f1e0;
  color: #462d2d;
  border: 1px solid #462d2d;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 1.2rem;
  cursor: pointer;
}

.btn-creme:hover {
  background-color: #d6d1b3;
  color: #462d2d;
}
.hide-scrollbar {
  scrollbar-color: transparent transparent;
}
hr
{ 
visibility: hidden;
margin: 0.3rem 0;
}

.btn-disabled {
  cursor: not-allowed;
}

.product-list {
  height: 450px;
}

.nav-pills .nav-link {
  color: #462d2d;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #462d2d;
}

.accordion-button:not(.collapsed) {
  color: #462d2d;
  background-color: #fff;
}

.sizeBtn-disabled, .sizeBtn-disabled:hover {
  cursor: not-allowed;
  background-color: #ddd;
  border: 1px solid #ddd;
  color: #fff;
}

.text-orange {
  color: #783c00;
}

.bg-orange {
  background-color: orange;
}

.z-index-100 {
  z-index: 100;
}

.fs-7 {
  font-size: 0.8rem;
}

.fs-8 {
  font-size: 0.7rem;
}

.fs-9 {
  font-size: 0.6rem;
}

.fs-10 {
  font-size: 0.5rem;
}

.mb-0-5 {
  margin-bottom: 0.125rem;
}
footer {
  position: relative;
}
footer::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 100%;
  background-image: url('./media/borderTry.webp');
  background-size: contain;
  top:-4px;
  left: 0px;
}
.border-brown {
  border: 2px solid #aaa2a2!important;
}

.social-icon {
  font-size: 1.4em;
}

.checkout-quantity {
  width: 96%!important;
}


@media (max-width: 768px) {
  .carousel-item, .carousel-item img:nth-child(1), .carousel-item img:nth-child(2) {
    height: 220px;
  }

  .navbarCategories {
    font-size: 0.9rem;
  }

  .product-card {
    width: 160px;
    height: 300px;
    margin: 10px 0px;
  }

  .search-page .product-card {
    width: 100%;
    height: 300px;
    margin: 10px 0px;
  }

  #image-container {
    height: calc(100vh - 400px)!important;
  }

  .w-sm-60 {
    width: 60%;
  }

  .m-sm-0 {
    margin: 0px!important;
  }

  .checkout-address {
    flex-direction: column;
  }

  .product-table {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .newLaunch-card {
    width: 230px;
    height: 230px;
  }

}
.checkout-address {
  display: flex;
  gap: 10px;
  padding: 0px;
}

.text-light-orange {
  color: #de6f00;
}

.orders__product-name {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}