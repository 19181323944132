.category-card > a{
    position: relative;
    display: block;
}
.category-image {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .category-card-wrapper {
    max-width: 800px;
  }
  .card-content {
    color: white;
  }

  @media  screen and (hover:hover) {
    .card-content{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        background: #000000b0 !important;
        align-items: center;
        font-size: 1.8em;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s all;
      }
      .card-content::before,.card-content::after {
        position: absolute;
        content: "";
        width: 0%;
        border: 1px solid white;
        height: 0%;
        transition: 0.8s all;
      }
      .card-content::before{
        top: 10px;
        left: 10px;
        border-right: 0;
        border-bottom: 0;
      }
      .card-content::after {
        bottom: 10px;
        right: 10px;
        border-top: 0;
        border-left: 0;
      }
      .category-card a:hover .card-content {
        visibility: visible !important;
        opacity: 1 !important;
      }
      .category-card a:hover .card-content::before, .category-card a:hover .card-content::after {
        width: 50%;
        height: 50%;
      } 
  }