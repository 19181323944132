#parallaxImage {
    display: block;
    max-width: 100%;
    height: 100%;
width: 100%;
object-fit: cover;
transition: 0.4s all;
}
#parallaxLink{
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
}
.text-content > * {
    display: block;
}
.text-content {
    position: absolute;
    padding: 40px;
    width: 100%;
    max-width: 450px;
    height: auto;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    max-height: 200px;
    text-align: center;
    color: white;
    gap: 10px;
}
.parallax.section {
    position: relative;
    overflow: hidden;
    max-height: 350px;
height: 100vh;
border-radius: 10px;
}
.parallax.section:hover #parallaxImage {
    transform: scale(1.1);
}
.parallax.section .parallax-background {
    backdrop-filter: blur(3px);
}
.parallax button {
    max-width: 150px;
    margin: 0px;
}
.parallax-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background:#00000080;
    padding: inherit;
    transition: 0.4s all;
    top: 0;
}
@media  screen and (hover:hover) {
    .parallax-background {
        backdrop-filter: initial;
    }
}