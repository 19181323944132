.announcement-bar {
    width: 100%;
    background-color: #f6b795;
    color: #462d2d;
}
.announcement-text {
    font-weight: normal;
text-align: center;
width: 100%;
margin: 0px;
}
nav.navbar {
    padding-top: 0px;
}
.sticky-head{
    position: sticky;
    z-index: 25;
    top:0;
}
.nav-category {
    top: 74px;
}
.opened-nav .nav-category {
    top: 194px;
  }
@media screen and (min-width:925px) {
    
}
