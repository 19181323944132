.product-title {
    line-height: 1;
    margin-bottom: 5px; 
}
.wishlist-icon {
    width: 25px;
}
.media-thumbnail {
    max-width: 545px;
}
.buy-buttons {
    max-width: 800px;
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 5px;
    z-index: 11;
    background-color: var(--bg-primary);
}
.media-container-sticky {
    top: 135px;
}
.media-thumbnail-item {
    min-width: 100px;
}



@media screen and (hover:hover) {
    .buy-buttons{
        position: static;
    }
}